import React from "react";
import logo1 from "../../assets/img/logos/judywhite.webp";
import {
  EnvelopeFill,
  Facebook,
  GeoAltFill,
  Instagram,
  Linkedin,
  TelephoneFill,
  Twitter,
} from "react-bootstrap-icons";

const Footer = () => {
  return (
    <div id="footer" className="footer" style={{ direction: "rtl" }}>
      <div className="footer-content">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <div href="/" className="logo d-flex align-items-center">
                <img src={logo1} alt="Logo" />
              </div>
              <p>
                شركة متخصصة بإدارة الاستثمار و تأسيس الشركات الإستثمارية في
                السوق التركي
              </p>

              <div className="social-links d-flex  mt-5 ">
                <a
                  href="https://x.com/judyinvest"
                  target="blank"
                  className="twitter"
                >
                  <Twitter />
                </a>
                <a
                  href="https://www.facebook.com/Judyinvest"
                  target="blank"
                  className="facebook"
                >
                  <Facebook />
                </a>
                <a
                  href="https://www.instagram.com/Judyinvest"
                  target="blank"
                  className="insta"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/judyinvest"
                  target="blank"
                  className="linkedin"
                >
                  <Linkedin />
                </a>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 footer-info key footer-mar mr">
              <div className="col-lg-12 col-md-12 footer-contact">
                <div>
                  <div
                    dir="ltr"
                    style={{ padding: "20px", borderRadius: "10px" }}
                  >
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li
                        style={{
                          marginBottom: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TelephoneFill
                          color="#E8AF84"
                          style={{
                            marginRight: "15px",
                          }}
                        />
                        <span style={{ color: "#F7F9FC" }}>
                          +90 539 706 36 90
                        </span>
                      </li>
                      <li
                        style={{
                          marginBottom: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EnvelopeFill
                          style={{
                            marginRight: "15px",
                            color: "#E8AF84",
                          }}
                        />

                        <span style={{ color: "#F7F9FC" }}>
                          contact@judyinvest.com
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GeoAltFill
                          style={{
                            marginRight: "15px",
                            color: "#E8AF84",
                          }}
                        />

                        <span style={{ color: "#F7F9FC" }}>
                          MAHMUTBEY MAH.HACI BOSTAN CAD.
                          <br />
                          NO.22 BAGCILAR - ISTANBUL
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="social-link d-flex  mt-3 ">
          <a href="" className="twitter">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-twitter-x"
              viewBox="0 0 16 16"
            >
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
            </svg>{" "}
          </a>
          <a href="" className="facebook">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="" className="insta">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="" className="linkedin">
            <i className="bi bi-linkedin"></i>
          </a>
        </div> */}
      </div>

      {/* <div className="footer-legal">
        <div className="container">
          <div className="copyright">
            <strong>
              <span>Judy</span>
            </strong>
            .
          </div>
          <div className="credits">
            <a href="https://smartinb.com/">Smartinb</a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
